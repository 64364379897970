html {
  font-size: 14px;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
}

table {
  border-collapse: collapse;
}

table tbody {
  border-style: solid;
  border-color: var(
    --spectrum-alias-border-color-mid,
    var(--spectrum-global-color-gray-300)
  );
  background-color: var(--spectrum-global-color-gray-50);
  border-width: var(
    --spectrum-alias-border-size-thin,
    var(--spectrum-global-dimension-static-size-10)
  );
  border-radius: var(
    --spectrum-alias-border-radius-regular,
    var(--spectrum-global-dimension-size-50)
  );
}

table tr {
  border-bottom: 1px solid var(--spectrum-global-color-gray-300);
}
table tr:last-child {
  border-bottom: none;
}

table tr th,
table tr td {
  padding: 10px;
  text-align: center;
}
